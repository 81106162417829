// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import { HeaderContext } from 'context/header';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';

import { device } from 'components/device';
import Accordion from 'components/Accordion';

// Styles
export const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${({ marginTopLap }) => (marginTopLap ? '1.875rem' : '0')};
  }
`;

export const AccordionContainer = styled.div`
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const AccordionContainerTitle = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    if (findFragment?.localizations.length > 0) {
      const { markdown } = flatten(findFragment.localizations)[0];
      return markdown;
    }
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  const featuredMarketingContent = page[0]?.featuredMarketing;

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} borderBottom notLeft marginTopLap>
          <PromptServiceContent //Sobre
            markdown={getMarkdown(page, 'cktblmijkb5670c733tv6zlgz', true)}
            fragments={getMarkdown(page, 'cktblmijkb5670c733tv6zlgz')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isOncologia
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <AccordionContainer>
            <div>
              <Accordion //Quero ser doador
                markdownContent={getMarkdown(
                  page,
                  'cktblokm81f960c22zyfrgo3o',
                  true
                )}
              />
              <Accordion //Doação de sangue total
                markdownContent={getMarkdown(
                  page,
                  'cktblqez4b7yn0b21ood7po07',
                  true
                )}
              />
              <Accordion //Doação automatizada
                markdownContent={getMarkdown(
                  page,
                  'cktblruo8bd7f0a1650ho52b9',
                  true
                )}
              />
              <Accordion //Quem pode doar
                markdownContent={getMarkdown(
                  page,
                  'cktbltl68bavx0b21clzt7cxs',
                  true
                )}
              />
            </div>
          </AccordionContainer>
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent //Agendar uma doação
            markdown={getMarkdown(page, 'cktix0q401npg0b36ruxjxk5b', true)}
            fragments={getMarkdown(page, 'cktix0q401npg0b36ruxjxk5b')}
            padding="0 0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isOncologia
            withoutBorderTop
            isFormEmail
            isPageDoeSangue
            infusion
            contentTagUlListStyleType="none"
            isMarginLeftPromptService
            infusionAdjustBulletPoint
          />
        </Part>
      </GridContainer>
    </>
  );
};
const DoeSangue = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'cktbljasw1aqp0c225ch53e83', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cktble3awayml0b21cssoxtwq" }) {
                featuredMarketing(locales: [pt_BR, en, es]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments(locales: [pt_BR, en, es]) {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <DoeSangue page={response.gcms.site.pages} />;
      }}
    />
  );
};
